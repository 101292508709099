import * as React from 'react'
// import Calculator from '../components/calculator/Calculator'
import '../styles/style.css'

const Test = () => {

    return (
        // <Calculator ticker={'TSLA'} broker={['https://robinhood.com/stocks/', '']} dark={true}/>
        <>
        Hi
        </>
    )
}

export default Test;